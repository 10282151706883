export const useUtilFunctions = () => {
    /**
     * Check the occurrence of an opening and closing HTML tag
     * @param input which should be checked
     * @returns status if the input contains an HTML tag
     */
    const checkHtmlTags = (input: string): boolean => {
        const tagPattern = /<([a-zA-Z0-9]+)[^>]*>.*<\/\1>/g;
        return !input.match(tagPattern);
    };

    /**
     * Checks if the quotes inside the given string match
     * Every quote has to be closed
     * @param input string which should be checked
     * @returns status if the input contains only matching quotes
     */
    const checkMatchingQuotes = (input: string): boolean => {
        let currPos = 0;
        let numberOfQuotes = 0;
        while (currPos !== -1 && currPos < input.length) {
            currPos = input.indexOf('"', currPos);
            if (currPos !== -1) {
                numberOfQuotes = numberOfQuotes + 1;
                currPos = currPos + 1;
            }
        }
        return numberOfQuotes % 2 === 0;
    };

    /**
     * Checks the given string and returns status if its container angle brackets
     * @param input string which should be checked
     * @returns status if the string container angle brackets (> or <)
     */
    const checkAngleBrackets = (input: string): boolean => {
        return input.includes('>') || input.includes('<');
    };

    /**
     * Looks up and returns the scrollcontainer of the given element
     * @param node element of which the scrollcontainer should be found
     * @returns the found scrollcontainer or null
     */
    const getScrollContainer = (
        node?: HTMLElement | null,
    ): null | HTMLElement => {
        if (node != null && node instanceof HTMLElement) {
            if (node.scrollHeight > node.clientHeight) {
                return node;
            }
            return getScrollContainer(node.parentNode as HTMLElement | null);
        }
        return null;
    };

    return {
        checkAngleBrackets,
        checkHtmlTags,
        checkMatchingQuotes,
        getScrollContainer,
    };
};
